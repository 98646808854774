import React, { useEffect } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import Footer from "../components/Context/Footer";
import HeroGradient from "../components/General/HeroGradient";
import FeaturedBlock from "../components/News/FeaturedBlock";
import ArticleBlock from "../components/General/ArticleBlock";
import CTA from "../components/General/CTA";

import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const NewsListing = ({ data: staticData, pageContext, location }) => {
    const data = usePreviewData(staticData);
    const isFirstPage = pageContext.currentPage === 1;
    const {name, meta_description, share_image, tagline, heading_promo, teaser, button_cta, link_promo} = data.prismicNewsListing.data;
    useEffect(() => {
        let timer;
        if(window && location.state && location.state.fromPagination && location.action === "PUSH") {
            timer = setTimeout(() => {
                let content = document.querySelector('#content')
                if(content) {
                    window.scrollTo(0, content.offsetTop - 200);
                }
            }, 350)

        }
        return () => {
            clearTimeout(timer);
        }

    }, [location]);
    const articles = data.allPrismicNewsArticle.nodes.map(article => {
        return {
            url: article.data.external_platform ? article.data.external_article_link.url : article.url,
            description: article.data.summary.html,
            date: article.data.date,
            title: article.data.name.text,
            author: article.data.author,
            type: article.data.external_platform || null,
            buttonText: `Read this article${article.data.external_platform ? ` on ${article.data.external_platform}` : ""}`,
            objectID: article.id,
            key: article.id,
        }
    });
    const featuredArticles = articles.slice(0,3);
    const otherArticles = isFirstPage ? articles.slice(3) : articles;
    const theme = "light";
    return (
        <Layout theme={theme}>
            <SEO title={name.text} description={meta_description} image={share_image.url} />
            <Header theme={theme} />
            <HeroGradient
                title={name.text}
                subtitle={tagline.html}
                theme={theme}
            />
            <span id={"content"} />
            {isFirstPage ?
                <FeaturedBlock
                    theme={theme}
                    title="Latest articles"
                    featured={featuredArticles}
                />
            : null}
            <ArticleBlock
                theme={theme}
                articles={otherArticles}
                pagination={{
                    currentPage: pageContext.currentPage,
                    numPages: pageContext.numPages,
                    baseUrl: "/news/"
                }}
            />
            <CTA
                theme={theme}
                title={heading_promo.text}
                content={teaser.html}
                buttonText={button_cta}
                buttonUrl={link_promo.url}
                buttonInternal={true}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    query newsArticlesQuery($skip: Int!, $limit: Int!) {
        allPrismicNewsArticle(sort: {fields: data___date, order: DESC}, limit: $limit, skip: $skip) {
            nodes {
                uid
                url
                data {
                    author
                    date(formatString: "MMM D YYYY")
                    summary {
                        html
                    }
                    external_platform
                    external_article_link {
                        url
                        target
                        link_type
                    }
                    name {
                        text
                    }
                }
            }
        }
        prismicNewsListing {
            data {
                name {
                    text
                }
                tagline {
                    html
                }
                heading_promo {
                    text
                }
                teaser {
                    html
                }
                button_cta
                link_promo {
                    url
                    target
                    link_type
                }
                meta_description
                share_image {
                    url
                }
            }
        }
        
    }
`;

export default NewsListing;
